var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-inline" },
    [
      _c(
        "div",
        { staticClass: "ma-0 pa-0 d-inline" },
        [
          _vm._t("default", function() {
            return [
              _vm.edit
                ? _c(
                    "b-button",
                    {
                      attrs: { variant: "success", title: "Edit Question" },
                      on: {
                        click: function($event) {
                          _vm.addeditquestion = true
                        }
                      }
                    },
                    [_c("b-icon", { attrs: { icon: "pencil-square" } })],
                    1
                  )
                : _c(
                    "b-button",
                    {
                      attrs: { variant: "success", block: "" },
                      on: {
                        click: function($event) {
                          _vm.addeditquestion = true
                        }
                      }
                    },
                    [
                      _c("b-icon", { attrs: { icon: "plus" } }),
                      _vm._v("Add Question")
                    ],
                    1
                  )
            ]
          })
        ],
        2
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: "Add/Edit Question",
            "no-close-on-backdrop": true,
            "ok-variant": "primary",
            size: "xl"
          },
          on: {
            ok: _vm.saveQuestion,
            hidden: function($event) {
              return _vm.clearForm()
            },
            show: function($event) {
              return _vm.init()
            }
          },
          scopedSlots: _vm._u([
            {
              key: "modal-ok",
              fn: function() {
                return [
                  _c("b-icon", { attrs: { icon: "check" } }),
                  _vm._v(" Save ")
                ]
              },
              proxy: true
            },
            {
              key: "modal-cancel",
              fn: function() {
                return [
                  _c("b-icon", { attrs: { icon: "x" } }),
                  _vm._v(" Cancel ")
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.addeditquestion,
            callback: function($$v) {
              _vm.addeditquestion = $$v
            },
            expression: "addeditquestion"
          }
        },
        [
          _c(
            "b-form",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "question-group",
                            label: "Question:",
                            "label-for": "question"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "question",
                              placeholder: "Question text",
                              required: ""
                            },
                            model: {
                              value: _vm.form.question,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "question", $$v)
                              },
                              expression: "form.question"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: {
                            id: "questioninactive",
                            name: "questioninactive",
                            value: 1,
                            "unchecked-value": 0
                          },
                          model: {
                            value: _vm.form.setinactive,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "setinactive", $$v)
                            },
                            expression: "form.setinactive"
                          }
                        },
                        [_vm._v(" Set as Inactive ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            { staticStyle: { "margin-top": "20px" } },
            [
              _c("b-col", { attrs: { cols: "12" } }, [
                _c(
                  "fieldset",
                  [
                    _c("legend", [_vm._v("Answers")]),
                    _c("HelpAnswerModal", {
                      attrs: {
                        id: "addmodal",
                        question: _vm.form,
                        roles: _vm.roles
                      },
                      on: { refresh: _vm.loadAnswers }
                    }),
                    _vm.form.answers.length == 0
                      ? _c(
                          "b-alert",
                          {
                            staticClass: "text-center text-danger",
                            attrs: { variant: "warning", show: "" }
                          },
                          [_vm._v("No Answers Found")]
                        )
                      : _vm._e(),
                    _vm.form.answers.length > 0
                      ? _c(
                          "table",
                          {
                            staticClass: "table table-striped",
                            staticStyle: { "background-color": "white" }
                          },
                          [
                            _c("thead", [
                              _c("th", [_vm._v("ID")]),
                              _c("th", [_vm._v("Display")]),
                              _c("th", { staticClass: "text-left" }, [
                                _vm._v("Answer")
                              ]),
                              _c("th", { staticClass: "text-left" }, [
                                _vm._v("Type")
                              ]),
                              _c("th", { staticClass: "text-left" }, [
                                _vm._v("Visible To")
                              ]),
                              _c("th", [_vm._v("Active")]),
                              _c("th")
                            ]),
                            _c(
                              "tbody",
                              _vm._l(_vm.form.answers, function(row, idx) {
                                return _c("tr", { key: idx }, [
                                  _c("td", [_vm._v(_vm._s(row.id))]),
                                  _c("td", [_vm._v(_vm._s(row.display))]),
                                  _c("td", { staticClass: "text-left" }, [
                                    _vm._v(
                                      _vm._s(_vm._f("truncate")(row.content))
                                    )
                                  ]),
                                  _c("td", { staticClass: "text-left" }, [
                                    _vm._v(_vm._s(row.answertype))
                                  ]),
                                  _c("td", { staticClass: "text-left" }, [
                                    _vm._v(_vm._s(row.rolenames))
                                  ]),
                                  _c("td", [_vm._v(_vm._s(row.active))]),
                                  _c(
                                    "td",
                                    { staticClass: "text-right" },
                                    [
                                      _c(
                                        "b-button-group",
                                        [
                                          _c("HelpAnswerModal", {
                                            attrs: {
                                              id: "editmodal",
                                              question: _vm.form,
                                              answer: row,
                                              roles: _vm.roles,
                                              edit: ""
                                            },
                                            on: { refresh: _vm.loadAnswers }
                                          }),
                                          _c(
                                            "b-button",
                                            {
                                              attrs: {
                                                variant: "danger",
                                                title: "Remove Answer"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.removeAnswer(row)
                                                }
                                              }
                                            },
                                            [
                                              _c("b-icon", {
                                                attrs: { icon: "x-square" }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ])
                              }),
                              0
                            )
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }