var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-inline" },
    [
      _c(
        "div",
        { staticClass: "ma-0 pa-0 d-inline" },
        [
          _vm._t("default", function() {
            return [
              _vm.edit
                ? _c(
                    "b-button",
                    {
                      attrs: { variant: "success", title: "Edit Answer" },
                      on: {
                        click: function($event) {
                          _vm.addeditanswer = true
                        }
                      }
                    },
                    [_c("b-icon", { attrs: { icon: "pencil-square" } })],
                    1
                  )
                : _c(
                    "b-button",
                    {
                      attrs: { variant: "success", block: "" },
                      on: {
                        click: function($event) {
                          return _vm.checkQuestion()
                        }
                      }
                    },
                    [
                      _c("b-icon", { attrs: { icon: "plus" } }),
                      _vm._v("Add Answer")
                    ],
                    1
                  )
            ]
          })
        ],
        2
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: "Add/Edit Answer",
            "no-close-on-backdrop": true,
            "no-enforce-focus": true,
            "ok-variant": "primary",
            size: "xl"
          },
          on: {
            ok: _vm.saveAnswer,
            hidden: function($event) {
              return _vm.defaultModal()
            },
            show: function($event) {
              return _vm.defaultModal()
            }
          },
          scopedSlots: _vm._u([
            {
              key: "modal-ok",
              fn: function() {
                return [
                  _c("b-icon", { attrs: { icon: "check" } }),
                  _vm._v(" Save ")
                ]
              },
              proxy: true
            },
            {
              key: "modal-cancel",
              fn: function() {
                return [
                  _c("b-icon", { attrs: { icon: "x" } }),
                  _vm._v(" Cancel ")
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.addeditanswer,
            callback: function($$v) {
              _vm.addeditanswer = $$v
            },
            expression: "addeditanswer"
          }
        },
        [
          _c(
            "b-form",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "b-form-group",
                        { attrs: { id: "text-group", label: "Text:" } },
                        [
                          _c(
                            "div",
                            [
                              _c("editor", {
                                key: 1,
                                attrs: {
                                  id: "editor",
                                  "api-key":
                                    "83u69vpf23jbsp27k3ufq78a7fp4jxwdd5xhrtbkhpueukuu",
                                  init: _vm.tinymceConfig
                                },
                                model: {
                                  value: _vm.form.answer.content,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form.answer, "content", $$v)
                                  },
                                  expression: "form.answer.content"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "4" } },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: {
                            id: "answerinactive",
                            name: "answerinactive",
                            value: 1,
                            "unchecked-value": 0
                          },
                          model: {
                            value: _vm.form.setinactive,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "setinactive", $$v)
                            },
                            expression: "form.setinactive"
                          }
                        },
                        [_vm._v(" Set as Inactive ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "4" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "visible-group",
                            label: "Visible to Roles:"
                          }
                        },
                        [
                          _c(
                            "b-form-checkbox-group",
                            {
                              attrs: {
                                id: "visible-checkbox-group",
                                name: "roles"
                              },
                              model: {
                                value: _vm.form.roles,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "roles", $$v)
                                },
                                expression: "form.roles"
                              }
                            },
                            _vm._l(_vm.roles, function(role, idx) {
                              return _c(
                                "b-form-checkbox",
                                { key: idx, attrs: { value: role.id } },
                                [_vm._v(_vm._s(role.name))]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "text-right", attrs: { cols: "4" } },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: {
                            id: "setdefault",
                            name: "setdefault",
                            value: 1,
                            "unchecked-value": 0
                          },
                          model: {
                            value: _vm.form.setdefault,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "setdefault", $$v)
                            },
                            expression: "form.setdefault"
                          }
                        },
                        [_vm._v(" Set as Default ")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "b-form-group",
                        { attrs: { id: "file-group", label: "File Upload:" } },
                        [
                          _c("b-form-file", {
                            attrs: {
                              state: Boolean(_vm.form.file1),
                              placeholder: "Choose a file or drop it here...",
                              "drop-placeholder": "Drop file here...",
                              multiple: ""
                            },
                            model: {
                              value: _vm.form.file1,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "file1", $$v)
                              },
                              expression: "form.file1"
                            }
                          }),
                          _c("div", { staticClass: "mt-3" }, [
                            _vm._v(
                              " Selected file: " +
                                _vm._s(
                                  _vm.form.file1 ? _vm.form.file1.name : ""
                                ) +
                                " "
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.form.answer.answertype == "Help Screenshot"
                ? _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { staticClass: "text-left", attrs: { cols: "12" } },
                        [
                          _c("img", {
                            staticClass: "view-img",
                            attrs: { src: _vm.form.answer.url }
                          })
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.answer.answertype == "Help Video"
                ? _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { staticClass: "text-left", attrs: { cols: "12" } },
                        [
                          _c(
                            "div",
                            { staticStyle: { width: "300px !important" } },
                            [
                              _c(
                                "b-embed",
                                {
                                  attrs: {
                                    type: "video",
                                    controls: "",
                                    allowfullscreen: ""
                                  }
                                },
                                [
                                  _c("source", {
                                    attrs: {
                                      src: _vm.form.answer.url,
                                      type: "video/mp4"
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-form-group",
                { attrs: { id: "display-group", label: "Display Order:" } },
                [
                  _c("b-input", {
                    attrs: { type: "number", min: "0", step: "1" },
                    model: {
                      value: _vm.form.answer.display,
                      callback: function($$v) {
                        _vm.$set(_vm.form.answer, "display", $$v)
                      },
                      expression: "form.answer.display"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }