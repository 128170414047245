var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            {
              staticClass: "text-md-left text-center text--white",
              attrs: { cols: "12", md: "6", lg: "4", xl: "3" }
            },
            [_c("span", { staticClass: "h4" }, [_vm._v("Help Admin")])]
          ),
          _c(
            "b-col",
            {
              staticClass: "text-right",
              attrs: {
                cols: "12",
                md: "6",
                lg: "4",
                "offset-lg": "4",
                xl: "3",
                "offset-xl": "6"
              }
            },
            [
              _c("HelpQuestionModal", {
                attrs: { id: "addmodal", roles: _vm.roles },
                on: {
                  refresh: function($event) {
                    return _vm.init()
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-card",
        { staticClass: "mt-4" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-form",
                    {
                      ref: "helpForm",
                      attrs: { action: "" },
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.search()
                        }
                      }
                    },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { staticClass: "text-left", attrs: { cols: "4" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    id: "keywords-group",
                                    label: "Keywords:"
                                  }
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      id: "keywords",
                                      placeholder: "Question or Answer text"
                                    },
                                    model: {
                                      value: _vm.form.keywords,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "keywords", $$v)
                                      },
                                      expression: "form.keywords"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { staticClass: "text-left", attrs: { cols: "4" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    id: "visible-group",
                                    label: "Visible To:"
                                  }
                                },
                                [
                                  _c(
                                    "b-form-checkbox-group",
                                    {
                                      attrs: {
                                        id: "visible-checkbox-group",
                                        name: "flavour-2"
                                      },
                                      model: {
                                        value: _vm.form.roles,
                                        callback: function($$v) {
                                          _vm.$set(_vm.form, "roles", $$v)
                                        },
                                        expression: "form.roles"
                                      }
                                    },
                                    _vm._l(_vm.roles, function(role, idx) {
                                      return _c(
                                        "b-form-checkbox",
                                        { key: idx, attrs: { value: role.id } },
                                        [_vm._v(_vm._s(role.name))]
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { staticClass: "text-left", attrs: { cols: "4" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    id: "answertype-group",
                                    label: "Answer Type:"
                                  }
                                },
                                [
                                  _c(
                                    "b-form-checkbox-group",
                                    {
                                      attrs: {
                                        id: "answertype-checkbox-group",
                                        name: "flavour-2"
                                      },
                                      model: {
                                        value: _vm.form.types,
                                        callback: function($$v) {
                                          _vm.$set(_vm.form, "types", $$v)
                                        },
                                        expression: "form.types"
                                      }
                                    },
                                    [
                                      _c(
                                        "b-form-checkbox",
                                        { attrs: { value: "text" } },
                                        [_vm._v("Text")]
                                      ),
                                      _c(
                                        "b-form-checkbox",
                                        { attrs: { value: "image" } },
                                        [_vm._v("Screenshots")]
                                      ),
                                      _c(
                                        "b-form-checkbox",
                                        { attrs: { value: "video" } },
                                        [_vm._v("Videos")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { staticClass: "text-left", attrs: { cols: "4" } },
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: {
                                    id: "includeinactive",
                                    name: "includeinactive",
                                    value: true,
                                    "unchecked-value": false
                                  },
                                  model: {
                                    value: _vm.form.questioninactive,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "questioninactive",
                                        $$v
                                      )
                                    },
                                    expression: "form.questioninactive"
                                  }
                                },
                                [_vm._v(" Include Inactive Questions ")]
                              ),
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: {
                                    id: "defaultonly",
                                    name: "defaultonly",
                                    value: true,
                                    "unchecked-value": false
                                  },
                                  model: {
                                    value: _vm.form.defaultonly,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "defaultonly", $$v)
                                    },
                                    expression: "form.defaultonly"
                                  }
                                },
                                [_vm._v(" Default Answers Only ")]
                              )
                            ],
                            1
                          ),
                          _c("b-col", {
                            staticClass: "text-left",
                            attrs: { cols: "4" }
                          }),
                          _c(
                            "b-col",
                            { staticClass: "text-left", attrs: { cols: "4" } },
                            [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "6" } },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            block: "",
                                            variant: "warning"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.reset()
                                            }
                                          }
                                        },
                                        [_vm._v("Reset")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "6" } },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            block: "",
                                            variant: "primary"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.search()
                                            }
                                          }
                                        },
                                        [_vm._v("Search")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("br"),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c("b-row", [
                    _c("table", { staticClass: "table table-striped" }, [
                      _c("thead", [
                        _c("th", [_vm._v("ID")]),
                        _c("th", { staticClass: "text-left" }, [
                          _vm._v("Question")
                        ]),
                        _c("th", { staticClass: "text-right" }, [
                          _vm._v("Answer Count")
                        ]),
                        _c("th", { staticClass: "text-left" }, [
                          _vm._v("Visible To")
                        ]),
                        _c("th", [_vm._v("Active")]),
                        _c("th")
                      ]),
                      _c(
                        "tbody",
                        _vm._l(_vm.searchresults, function(row, idx) {
                          return _c("tr", { key: idx }, [
                            _c("td", [_vm._v(_vm._s(row.id))]),
                            _c("td", { staticClass: "text-left" }, [
                              _vm._v(_vm._s(row.question))
                            ]),
                            _c("td", { staticClass: "text-right" }, [
                              _vm._v(_vm._s(row.answercount))
                            ]),
                            _c("td", { staticClass: "text-left" }, [
                              _vm._v(_vm._s(row.rolenames))
                            ]),
                            _c("td", [_vm._v(_vm._s(row.active))]),
                            _c(
                              "td",
                              { staticClass: "text-right" },
                              [
                                _c(
                                  "b-button-group",
                                  [
                                    _c("HelpQuestionModal", {
                                      attrs: {
                                        id: "editmodal",
                                        question: row,
                                        roles: _vm.roles,
                                        edit: ""
                                      },
                                      on: {
                                        refresh: function($event) {
                                          return _vm.init()
                                        }
                                      }
                                    }),
                                    _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          variant: "danger",
                                          title: "Remove Question"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.removeQuestion(row)
                                          }
                                        }
                                      },
                                      [
                                        _c("b-icon", {
                                          attrs: { icon: "x-square" }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ])
                        }),
                        0
                      )
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }